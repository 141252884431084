import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';

import ProjectWrapper from './ProjectWrapper';
import { Text } from '../../common';
import { NavTab, NavTabs, NavTabsContainer } from '../../common/NewTab';
import DesignFormTab from '../Design/DesignFormTab';
import DesignInterviewTab from '../Design/DesignInterviewTab';
import Tooltip from '../../common/Tooltip';

const Container = styled.div`
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-item: center;
	border-bottom: 1px solid var(--deepBlue);
`;

export default function DesignScreen() {
	const loggedUser = useSelector(state => state.LoginReducer);
	const { projectId } = useParams();

	const [projectTitle, setProjectTitle] = useState(null);
	const [tasks, setTasks] = useState([]);
	const [taskList, setTaskList] = useState([]);
	const [questionTypeList, setQuestionTypeList] = useState([]);
	const [screenerTypeOption, setScreenerTypeOption] = useState([]);
	const [masterTags, setMasterTags] = useState([]);
	const [videoTaskList, setVideoTaskList] = useState([]);
	const [projectStatus, setprojectStatus] = useState([]);
	const [ValueTypeChoices, setValueTypeChoices] = useState([]);
	const [addQuestionDisable, setAddQuestionDisable] = useState(false);
	const[count ,setCount]=useState(0)
	const [step, setStep] = useState(1);
	
	const taskStatus = useSelector(state => state.TaskReducer?.editQuestion) ;
	const taskStatusCode = taskStatus?.StatusCode ;

	const dispatch = useDispatch();

	useEffect(() => {
		const Payload = {
			user_ID: loggedUser.userId,
			project_ID: projectId
		};
		dispatch({ type: 'RESUME_DATA', payload: Payload });

		maverickPortalhttp
			.makeRequest(
				maverickPortalApiEndPoint.Resume.url + '/' + loggedUser.userId + '/' + projectId,
				maverickPortalApiEndPoint.Resume.methodType
			)
			.then(response => {
				let projectData = response.data.Response;
				setTasks(projectData?.projectTask_data);
				if (projectData?.project_data?.length > 0) {
					setProjectTitle(projectData?.project_data[0]?.identity)
					getTaskQuestion(projectData?.projectTask_data[0]?.taskType, projectData?.projectTask_data[0]?.projectTask_ID, projectData?.projectTask_data[0]?.IsVisited);
				}
				setprojectStatus(projectData?.projectStatus != undefined ? projectData?.projectStatus[0]?.steps_array != undefined ? projectData.projectStatus[0]?.steps_array : "" : "");
			});
	}, [taskList?.questionName_array]);

	useEffect(() => {
		maverickPortalhttp
			.makeRequest(
				maverickPortalApiEndPoint.Resume.url + '/' + loggedUser.userId + '/' + projectId,
				maverickPortalApiEndPoint.Resume.methodType
			)
			.then(response => {
				let projectData = response.data.Response;
				console.log("projectData",projectData)
				let ptasktype = projectData?.projectTask_data[1]?.taskType ;
				let ptaskid = projectData?.projectTask_data[1]?.projectTask_ID;
				let pisvisited = projectData?.projectTask_data[1]?.IsVisited;
				if (projectData?.projectTask_data?.length > 1 && ptasktype == "Video") {
					// getTaskQuestion(projectData?.projectTask_data[1]?.taskType, projectData?.projectTask_data[1]?.projectTask_ID, projectData?.projectTask_data[1]?.IsVisited);
					maverickPortalhttp
						.makeRequest(
							`${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${ptaskid}`,
							maverickPortalApiEndPoint.getVideoTask.methodType
						)
						.then(response => {
							let videoTaskData = response.data.Response;
							setMasterTags(videoTaskData?.masterTag);
						});
				}
			});

		setTimeout(() => {
			dispatch({ type: 'CLEAR_EDIT_QUESTION' });
		}, 800);
	}, [taskStatusCode])

	const getTaskQuestion = (taskType, taskId, isVisited) => {
		if (taskType == "Form") {
			maverickPortalhttp
				.makeRequest(
					`${maverickPortalApiEndPoint.getProjectTask.url}/${loggedUser.userId}/${projectId}/${taskId}`,
					maverickPortalApiEndPoint.getProjectTask.methodType
				)
				.then(response => {
					let taskData = response.data.Response;
					setTaskList(taskData?.GetProjectTaskQuestions)
					setQuestionTypeList(taskData?.questionType)
					setValueTypeChoices(taskData?.ValueTypeChoices);
					
				});
		}
		else if (taskType =="Video") {
			
			maverickPortalhttp
				.makeRequest(
					`${maverickPortalApiEndPoint.getVideoTask.url}/${loggedUser.userId}/${projectId}/${taskId}`,
					maverickPortalApiEndPoint.getVideoTask.methodType
				)
				.then(response => {
					let videoTaskData = response.data.Response;
					setVideoTaskList(videoTaskData?.GetProjectTaskQuestions)
					setQuestionTypeList(videoTaskData?.questionType)
					setScreenerTypeOption(videoTaskData?.dropDownForScreenerTypeQ)
					setMasterTags(videoTaskData?.masterTag);
					setValueTypeChoices(videoTaskData?.ValueTypeChoices);
				});
		}

		if (isVisited == 0) {
			const data = {
				user_ID: loggedUser.userId,
				projectTask_ID: taskId
			}
			dispatch({ type: 'REQUEST_UPDATE_TASK_STATUS', payload: data });
		}
		
	}

	return (
		<ProjectWrapper projectTitle={projectTitle} step={3} projectStatus={projectStatus} projectId={projectId}>
			<Text type='3'>
				Select a task and start editing the template to better suit
				your projects needs
			</Text>
			<Container className='mt-3'>
				<NavTabsContainer className='px-3 '>
					<NavTabs>
						{tasks?.map((task, taskIndex) => {
							return <NavTab
								key={taskIndex}
								active={step === (taskIndex + 1)}
								onClick={() => {
									setStep(taskIndex + 1)
									getTaskQuestion(task.taskType, task.projectTask_ID, task.IsVisited)
								}}
								fontSize='12px'
							>
								{task.taskType != "Video" ? task.taskType : "Interview"}
							</NavTab>
						})}
						<Tooltip
							left='-10px'
							top='-30px'
							direction='bottom'
							number='1/3'
							information='Select the task to view and edit the template'
						></Tooltip>
					</NavTabs>
				</NavTabsContainer>
			</Container>
			<div className='mt-3'>

				{tasks?.map((task, taskIndex) => {

					return <div key={taskIndex}>
						{
							((taskIndex + 1) == step && task.taskType == "Form") ? (
								<DesignFormTab
									task={task}
									nextTask={tasks.length == (taskIndex + 1) ? tasks[0] : tasks[taskIndex + 1]}
									taskList={taskList}
									questionTypeList={questionTypeList}
									ValueTypeChoices={ValueTypeChoices}
									addQuestionDisable={addQuestionDisable}
									setAddQuestionDisable={setAddQuestionDisable}
									handleTaskTab={(taskType, projectTask_ID, IsVisited) => {
										(projectTask_ID == tasks[0].projectTask_ID) ? setStep(1) : setStep(step + 1);
										getTaskQuestion(taskType, projectTask_ID, IsVisited);
									}}
								/>
							) :
								((taskIndex + 1) == step && task.taskType == "Video") && (
									<DesignInterviewTab
										task={task}
										nextTask={tasks.length == (taskIndex + 1) ? tasks[0] : tasks[taskIndex + 1]}
										videoTaskList={videoTaskList}
										questionTypeList={questionTypeList}
										screenerTypeOption={screenerTypeOption}
										ValueTypeChoices={ValueTypeChoices}
										masterTags={masterTags}
										addQuestionDisable={addQuestionDisable}
										setAddQuestionDisable={setAddQuestionDisable}
										handleTaskTab={(taskType, projectTask_ID, IsVisited) => {
											(projectTask_ID == tasks[0].projectTask_ID) ? setStep(1) : setStep(2);
											getTaskQuestion(taskType, projectTask_ID, IsVisited);
										}}
									/>
								)
						}
					</div>

				})}
			</div>
		</ProjectWrapper>
	);
}