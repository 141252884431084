import constants from '../../constants';

const {
  REQUEST_GET_PROJECT_TASK,
  PROJECT_TASK_RECEIVED,
  REQUEST_QUESTION_EDIT,
  REQUEST_UPDATE_PROJECT_SCREENER,
  REQUEST_ADD_SCREENER_VALIDATION_QUESTION,
  REQUEST_EDIT_SCREENER_ANSWER,
  EDIT_SCREENER_ANSWER_RECEIVED,
  REQUEST_SHOW_ScreenerValidation,
  SHOW_ScreenerValidation_Recieved,
  REQUEST_ADD_QUESTION_TAGS,
  REQUEST_DELETE_QUESTION_TAG,
  REQUEST_VIDEO_QUESTION_EDIT,
  QUESTION_EDIT_RECEIVED,
  REQUEST_VIDEO_QUESTION_RECEIVED,

  REQUEST_GET_VIDEO_TASK,
  VIDEO_TASK_RECEIVED,
  REQUEST_DELETE_QUESITON,
  DELETE_QUESITON_RECEIVED,
  REQUEST_DELETE_SECTION,
  DELETE_SECTION_RECEIVED,
  REQUEST_ADD_QUESITON,
  ADD_QUESITON_RECEIVED,
  REQUEST_SCHEDULE_DATES,
  REQUEST_SCHEDULE_DATES_RECEIVED,
  ADD_SCHEDULE_DATE,
  ADD_SCHEDULE_DATE_RECEIVED,
  RESET_ADD_SLOT_STATUS,
  RESET_DELETE_SLOT_STATUS,
  EDIT_SCHEDULE_DATE,
  EDIT_SCHEDULE_DATE_RECEIVED,
  DELETE_SCHEDULE_DATE,
  DELETE_SCHEDULE_DATE_RECEIVED,
  RECIEVED_UPDATE_DURATION,
  ADD_PROJECT_GROUP,
  EDIT_PROJECT_GROUP,
  DELETE_PROJECT_GROUP,
  PROJECT_GROUP_RECEIVED,
  CREATE_BUDGET,
  SAMPLE_AND_BUDGET_RECEIVED,
  ASSIGN_SAMPLE_AND_BUDGET,
  DEASSIGN_SAMPLE_AND_BUDGET,
  CREATE_PROJECT_LEAD,
  REQUEST_DELETEFORMTAG,
  DELETEFORMTAG_RECEIVED,

  CLEAR_ADD_QUESTION,
  CLEAR_EDIT_QUESTION,
  CLEAR_CRITERIS_AND_BUDGET_STATUS
} = constants.ProjectConstants;
export default {
  REQUEST_GET_PROJECT_TASK,
  PROJECT_TASK_RECEIVED,
  REQUEST_QUESTION_EDIT,
  REQUEST_UPDATE_PROJECT_SCREENER,
  REQUEST_ADD_SCREENER_VALIDATION_QUESTION,
  REQUEST_EDIT_SCREENER_ANSWER,
  EDIT_SCREENER_ANSWER_RECEIVED,
  REQUEST_SHOW_ScreenerValidation,
  SHOW_ScreenerValidation_Recieved,
  REQUEST_ADD_QUESTION_TAGS,
  REQUEST_DELETE_QUESTION_TAG,
  REQUEST_VIDEO_QUESTION_EDIT,
  QUESTION_EDIT_RECEIVED,
  REQUEST_VIDEO_QUESTION_RECEIVED,
  REQUEST_GET_VIDEO_TASK,
  RESET_DELETE_SLOT_STATUS,
  VIDEO_TASK_RECEIVED,
  REQUEST_DELETE_QUESITON,
  DELETE_QUESITON_RECEIVED,
  REQUEST_DELETE_SECTION,
  DELETE_SECTION_RECEIVED,
  REQUEST_ADD_QUESITON,
  ADD_QUESITON_RECEIVED,
  REQUEST_SCHEDULE_DATES,
  REQUEST_SCHEDULE_DATES_RECEIVED,
  ADD_SCHEDULE_DATE,
  ADD_SCHEDULE_DATE_RECEIVED,
  RESET_ADD_SLOT_STATUS,
  RESET_DELETE_SLOT_STATUS,
  EDIT_SCHEDULE_DATE,
  EDIT_SCHEDULE_DATE_RECEIVED,
  DELETE_SCHEDULE_DATE,
  DELETE_SCHEDULE_DATE_RECEIVED,
  RECIEVED_UPDATE_DURATION,
  ADD_PROJECT_GROUP,
  EDIT_PROJECT_GROUP,
  DELETE_PROJECT_GROUP,
  PROJECT_GROUP_RECEIVED,
  CREATE_BUDGET,
  SAMPLE_AND_BUDGET_RECEIVED,
  ASSIGN_SAMPLE_AND_BUDGET,
  DEASSIGN_SAMPLE_AND_BUDGET,
  REQUEST_DELETEFORMTAG,
  DELETEFORMTAG_RECEIVED,

  CREATE_PROJECT_LEAD,
  CLEAR_ADD_QUESTION,
  CLEAR_EDIT_QUESTION,
  CLEAR_CRITERIS_AND_BUDGET_STATUS,

  taskReceived: () => ({
    type: PROJECT_TASK_RECEIVED
  }),

  getQuestionEdit: payload => ({
    type: REQUEST_QUESTION_EDIT,
    payload
  }),

  updateProjectScreener: payload => ({
    type: REQUEST_UPDATE_PROJECT_SCREENER,
    payload
  }),

  addScreenerValidationQuestion: payload => ({
    type: REQUEST_ADD_SCREENER_VALIDATION_QUESTION,
    payload
  }),

  addQuestionTags: payload => ({
    type: REQUEST_ADD_QUESTION_TAGS,
    payload
  }),

  delteQuestionTag: payload => ({
    type: REQUEST_DELETE_QUESTION_TAG,
    payload
  }),

  resetdeleteScheduledateStatus: () => ({
    type: RESET_DELETE_SLOT_STATUS
  }),

  QuestionEditReceived: () => ({
    type: QUESTION_EDIT_RECEIVED
  }),

  requestDeleteFormTag: payload => ({
    type: REQUEST_DELETEFORMTAG,
    payload
  }),

  DeleteFormTagReceived: () => ({
    type: DELETEFORMTAG_RECEIVED
  }),

  getVideoTask: () => ({
    type: REQUEST_GET_VIDEO_TASK,
    payload
  }),

  videoTaskReceived: () => ({
    type: VIDEO_TASK_RECEIVED
  }),

  deleteQuestion: () => ({
    type: REQUEST_DELETE_QUESITON,
    payload
  }),

  deleteQuestionReceived: () => ({
    type: DELETE_QUESITON_RECEIVED
  }),

  deleteSection: () => ({
    type: REQUEST_DELETE_SECTION,
    payload
  }),

  deleteSectionReceived: () => ({
    type: DELETE_SECTION_RECEIVED
  }),
  requestAddQuestion: () => ({
    type: REQUEST_ADD_QUESITON,
    payload
  }),
  requestEditScreenerAnswer: () => ({
    type: REQUEST_EDIT_SCREENER_ANSWER,
    payload
  }),
  editScreenerReceived: () => ({
    type: EDIT_SCREENER_ANSWER_RECEIVED
  }),
  requestShowScreenerValidation: () => ({
    type: REQUEST_SHOW_ScreenerValidation,
    payload
  }),
  addQuestionReceived: () => ({
    type: ADD_QUESITON_RECEIVED
  }),

  requestScheduleDates: () => ({
    type: REQUEST_SCHEDULE_DATES,
    payload
  }),

  scheduleDateRecieved: () => ({
    type: REQUEST_SCHEDULE_DATES_RECEIVED
  }),

  addScheduleDate: () => ({
    type: ADD_SCHEDULE_DATE,
    payload
  }),

  addScheduleDateRecieved: () => ({
    type: ADD_SCHEDULE_DATE_RECEIVED
  }),
  sampleAndBudgetRecieved: () => ({
    type: SAMPLE_AND_BUDGET_RECEIVED
  }),

  resetaddScheduleDateStatus: () => ({
    type: RESET_ADD_SLOT_STATUS
  }),

  resetdeleteScheduledateStatus: () => ({
    type: RESET_DELETE_SLOT_STATUS
  }),

  editScheduleDate: () => ({
    type: EDIT_SCHEDULE_DATE,
    payload
  }),

  editScheduleDateRecieved: () => ({
    type: EDIT_SCHEDULE_DATE_RECEIVED
  }),

  deleteScheduleDate: () => ({
    type: DELETE_SCHEDULE_DATE,
    payload
  }),

  deleteScheduleDateRecieved: () => ({
    type: DELETE_SCHEDULE_DATE_RECEIVED
  }),

  updateDurationRecieved: data => ({
    type: RECIEVED_UPDATE_DURATION,
    payload: data
  })
};
export const getTask = payload => ({
  type: REQUEST_GET_PROJECT_TASK,
  payload
});
