import styled from '@emotion/styled';
import { Icon } from '../../common';

const StyledCheckbox = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;

	.outer {
		height: ${(props) => props.outerHeight || '13px'};
		width: ${(props) => props.outerWidth || '13px'};
		border-radius: 1px;
		border: 1px solid #e2e2e2;
		border-color: ${(props) => props.color || '#e2e2e2'};
		background: ${(props) => props.inactiveColor || 'transparent'};
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 1px;
		margin-right: 5px;
	}

	.inner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-54%, -48%);
		z-index: 1;
		opacity: 0;
		color: var(--white);

		font-size: ${(props) => props.iconSize || '12px'};
	}

	.text {
		font-size: ${(props) => props.fontSize || '12px'};
		font-weight: ${(props) => props.fontWeight || '500'};
		color: ${(props) => props.color || 'var(--quadColor)'};
	}

	input {
		display: none;

		:checked ~ .outer {
			border: 1px solid var(--primaryColor);
			border-color: ${(props) => props.color || 'var(--primaryColor)'};
			background: ${(props) => props.color || 'var(--primaryColor)'};
		}

		:checked ~ .outer .inner {
			opacity: 1;
		}

		:checked ~ .text {
			color: ${(props) => props.color || 'var(--primaryColor)'};
			font-weight: ${(props) => props.fontWeight || '800'};
		}
	}
`;

const handleCheckboxChange =()=>{
	setIsChecked(!isChecked);
	console.log("checkbox state :", isChecked);
  };

export default function Checkbox({
	children,
	name,
	value,
	checked,
	disabled=false,
	className = '',
	fontSize,
	onChange,
	isChecked,
	color,
	inactiveColor,
	iconSize,
	fontWeight,
	style,
	outerHeight,
	outerWidth,
}) {
	
	return (
		<StyledCheckbox
		    outerHeight={outerHeight}
			outerWidth={outerWidth}
			color={color}
			className={className}
			fontSize={fontSize}
			fontWeight={fontWeight}
			inactiveColor={inactiveColor}
			iconSize={iconSize}
			style={style}
		>
			<input
				type='checkbox'
				name={name}
				value={value}
				checked={isChecked || checked}
				disabled={disabled}
				onChange={onChange}
			/>
			<span className='outer'>
				<Icon className='material-icons-round inner'>done</Icon>
			</span>
			<span className='text'>{children}</span>
		</StyledCheckbox>
	);
}
