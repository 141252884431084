import React from 'react';
import { Heading } from '.';

function Question({
	question,
	children,
	border = '1px solid var(--greyBorder)',
	width = '200px',
}) {
	return (
		<div
			style={{
				borderBottom: border ? border : '',
				padding: '0.75rem 0',
			}}
			className='align-items-center'
		>
			<div>
				<Heading
					type={3}
					fontSize='0.75rem'
					fontWeight='500'
					color='var(--deepBlue)'
				>
					{question}
				</Heading>
			</div>

			{children}
		</div>
	);
}

export default Question;
