import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as getProjectTask from '../../utils/api/design/design';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });
export let editQuestionGlobal = null;
function* getTaskProject(payload) {
  const promise = getProjectTask.getProjectTask(payload);
  const { res } = yield promise;

  yield putResolve({ type: actions.ProjectActions.PROJECT_TASK_RECEIVED, payload: res.Response });
}

function* editTaskQuestion(payload) {
  const promise = getProjectTask.editTaskQuestion(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.QUESTION_EDIT_RECEIVED, payload: res });
}

function* updateProjectScreener(payload) {
  const promise = getProjectTask.updateProjectScreener(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.QUESTION_EDIT_RECEIVED, payload: res.Response });
}

function* addScreenerValidationQuestion(payload) {
  const promise = getProjectTask.addScreenerValidationQuestion(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.QUESTION_EDIT_RECEIVED, payload: res.Response });
}

function* addQuestionTags(payload) {
  const promise = getProjectTask.addQuestionTags(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.QUESTION_EDIT_RECEIVED, payload: res.Response });
}

function* deleteQuestionTag(payload) {
  const promise = getProjectTask.deleteQuestionTag(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.QUESTION_EDIT_RECEIVED, payload: res.Response });
}

function* DeleteFormTagData(payload) {
  const promise = getProjectTask.DeleteFormTagData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.DELETEFORMTAG_RECEIVED, data: res.Response });
}

function* editVideoQuestion(payload) {
  const promise = getProjectTask.editVideoQuestion(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.REQUEST_VIDEO_QUESTION_RECEIVED, payload: res.Response });
}

function* getTaskVideo(payload) {
  const promise = getProjectTask.getVideoTask(payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.VIDEO_TASK_RECEIVED, payload: res.Response });
}

function* deleteQuestion(payload) {
  const promise = getProjectTask.deleteQuestion(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.DELETE_QUESITON_RECEIVED, payload: res.Response });
}
function* deleteSection(payload) {
  const promise = getProjectTask.deleteSection(payload.formData);
  const { res } = yield promise;
  yield putResolve({ type: actions.ProjectActions.DELETE_SECTION_RECEIVED, payload: res.Response });
}
function* addQuestion(payload) {
  const promise = getProjectTask.addQuestion(payload.formData);
  const { res } = yield promise;

  yield putResolve({ type: actions.ProjectActions.ADD_QUESITON_RECEIVED, payload: res });
}
function* showScreenerFilterOnAnalysis(payload) {
  const promise = getProjectTask.showScreenerFilterOnAnalysis(payload.formData);
  const { res } = yield promise;

  yield putResolve({ type: actions.ProjectActions.SHOW_ScreenerValidation_Recieved, payload: res });
}
export {
  getTaskProject,
  editTaskQuestion,
  updateProjectScreener,
  addScreenerValidationQuestion,
  addQuestionTags,
  deleteQuestionTag,
  DeleteFormTagData,
  getTaskVideo,
  deleteQuestion,
  deleteSection,
  addQuestion,
  showScreenerFilterOnAnalysis,
  editVideoQuestion
};
