import styled from '@emotion/styled';
import React from 'react';
import { Card, Heading } from '../../common';
import DownIndicator from '../../common/DownIndicator';
import { Dropdown } from '../../components/form';
import AnalysisBarChart from './AnalysisBarChart';
import AnalysisLikert from './AnalysisLikert';
import AnalysisQuestion from './AnalysisQuestion';
import AnalysisWordCloud from './AnalysisWordCloud';
import img1 from '../../images/video_thumbnail/1.jpg';
import img2 from '../../images/video_thumbnail/2.jpg';
import img3 from '../../images/video_thumbnail/3.jpg';
import img4 from '../../images/video_thumbnail/4.jpg';
import img5 from '../../images/video_thumbnail/5.jpg';
import img6 from '../../images/video_thumbnail/6.jpg';
import img7 from '../../images/video_thumbnail/7.jpg';
import { id } from 'date-fns/locale';
import loading from '../../assets/images/loading.svg';
import { useState, useEffect } from 'react';
import { Button } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { dispatch } from 'd3';
import { REQUEST_CHECK_SUMMARY } from '../../constants/analysis/checkSummaryConstants';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const data1 = [{ img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }, { img: img6 }, { img: img7 }];

const data2 = [{ img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }, { img: img6 }, { img: img7 }];

const Header = styled(Card)`
  background: var(--midWhite);
  box-shadow: none;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const product_types = [
  { value: 1, label: 'Jump To' },
  { value: 2, label: 'New' }
];
const controlStyle = {
  minHeight: 'auto',
  width: '80px',
  '&:hover': {
    borderColor: 'var(--sapGreen)'
  }
};

const DownIndicatorCustom = ({ ...props }) => {
  return <DownIndicator color="var(--sapGreen)" iconStyle={{ fontSize: 16 }} {...props} />;
};

const ListContainer = styled.div`
  height: 600px;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 1rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
  }
  overflow-y: scroll;

  .question {
    padding-top: 1rem;
  }
  .question:first-of-type {
    padding-top: 0;
  }
`;

const AnalysisListHeader = ({ title, children }) => {
  return (
    <>
      <Header withBorder={false}>
        <Heading fontSize="14px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
          {title}
        </Heading>
        <Dropdown
          selectedFontSize="12px"
          selectedFontWeight="400"
          options={product_types}
          isSummary
          selectedColor="var(--deepBlue)"
          defaultValue={{
            value: 1,
            label: 'Jump To'
          }}
          controlStyle={controlStyle}
          valueContainerStyle={{
            padding: '0'
          }}
          indicatorContainer={{ padding: '0' }}
          selectedBorderBottom="1px solid var(--sapGreen)"
          DropdownIndicator={props => <DownIndicatorCustom {...props} />}
        />
      </Header>
      {children}
    </>
  );
};

function AnalysisList({
  onCopyClick,
  analysisData,
  filters,
  getClipsData,
  dropDowns,
  dataType,
  cTag,
  vType,
  isGenerated,
  interviewsCompleted
}) {
  var isSummary = 0;
  const [summaryData, setSummaryData] = useState('');
  const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);

  let project_id = useParams(id);
  const [summaryWithQid, setSummaryWithQid] = useState('');
  const [summary, setSummary] = useState('');
  const [sliceSummary, setSliceSummary] = useState('');

  var onLoad = false;

  // console.log(getClipsData)

  // useEffect(()=>{
  // 	onLoad = true;
  // 	console.log(onLoad);
  // },[])

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);

  const constSummary = 'video summary';

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setIsLoadingView(false);
      setIsLoadingVideo(false);
    }, 10000); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [getClipsData]);

  useEffect(() => {
    const timerMp3 = setTimeout(() => {
      setIsLoadingAudio(false);
    }, 10000); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timerMp3);
  }, [analysisData]);

  // var summaryData = ''
  // const summaryData = useSelector(state=>state.getSummaryReducer.Analysisdata);

  var flag = 0;
  let firstClipData = null;
  const checkFlag = firstClipData => {
    for (var i = 0; i < firstClipData.answer.length; i++) {
      if (
        firstClipData.answer[i]?.ClipUpdateStatusSummary !== '0' &&
        firstClipData.answer[i]?.ClipUpdateStatusSummary !== null
      ) {
        flag = 1;
      }
    }
  };

  const handleCopyClick = e => {
    // e.preventDefault();
    onCopyClick(prev => [
      ...prev,
      {
        title: 'Storage Directors',
        edit: true
      }
    ]);
  };
  return (
    <>
      <ListContainer>
        {analysisData?.map((a, i) => {
          return (
            <AnalysisListHeader title={(i + 1).toString() + ' | ' + a.sectionName} key={i}>
              <div className="px-3" key={a.questionPaperSection_ID}>
                {a.questionName_array.map((q, j) => {
                  {
                    let hmlist = [];
                    if (getClipsData) {
                      const relevantClipsData = getClipsData?.find(cd =>
                        cd.questionName_array.some(q2 => q.question_ID === q2.question_ID)
                      );
                      if (isGenerated && isGenerated[q.question_ID]) {
                        isSummary = isGenerated[q.question_ID][0].isSummary;
                      } else {
                        isSummary = 0;
                      }

                      // console.log('clips data',relevantClipsData);
                      // if ((q.questionType_ID === 10 && relevantClipsData)) {
                      if (
                        q.questionType_ID === 10 &&
                        q.answer !== null &&
                        (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                        relevantClipsData
                      ) {
                        const clipsData = relevantClipsData.questionName_array.filter(
                          q2 => q.question_ID === q2.question_ID
                        );

                        if (clipsData.length > 0) {
                          firstClipData = clipsData[0];
                          checkFlag(firstClipData);
                          firstClipData.answer.map((item, index) => {
                            let tagH = [];
                            item.tags.map((a, i) => {
                              if (a.tag == 'Hits') {
                                tagH.push('Hits');
                              }
                              if (a.tag == 'Misses') {
                                tagH.push('Misses');
                              }
                            });
                            if (!cTag && isLoadingVideo == false && vType.length > 0) {
                              if (vType.indexOf(item.buttonName) != -1 || tagH.some(val => vType.includes(val))) {
                                hmlist.push(item);
                              }
                            }
                          });
                          // const [isLoading, setIsLoading] = useState(true);
                          // const [isLoadingView, setIsLoadingView] = useState(true);
                          // const [isLoadingVideo, setIsLoadingVideo] = useState(true);

                          // useEffect(() => {

                          // 	const timer = setTimeout(() => {
                          // 		setIsLoading(false);
                          // 		setIsLoadingView(false);
                          // 		setIsLoadingVideo(false);
                          // 	}, 10000); // Adjust the delay time (in milliseconds) as needed

                          // 	return () => clearTimeout(timer);
                          // }, []);

                          if (isLoading) {
                            return (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid var(--greyBlue30)'
                                  }}
                                >
                                  <div style={{ width: '40%' }}>
                                    <Heading
                                      fontSize="14px"
                                      fontWeight="700"
                                      color="var(--deepBlue)"
                                      className="mb-0 pb-0 mt-3"
                                      breakHeading={false}
                                      style={{ marginRight: '5px' }}
                                    >
                                      {q.questionName}
                                      <SummaryTextContainer
                                        type={'video'}
                                        interviewsCompleted={interviewsCompleted}
                                        setSliceSummary={setSliceSummary}
                                        setSummary={setSummary}
                                        summary={summary}
                                        isMp4={false}
                                        isLoadingVideo={isLoadingVideo}
                                        setIsLoadingView={setIsLoadingView}
                                        setSummaryWithQid={setSummaryWithQid}
                                        summaryWithQid={summaryWithQid}
                                        question_ID={q.question_ID}
                                        id={firstClipData?.answer[0]?.projectRepondentVideo_ID}
                                        project_id={project_id.id}
                                        setSelectedGenerateSummary={setSelectedGenerateSummary}
                                        selectedGenerateSummary={selectedGenerateSummary}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        flag={flag}
                                        isSummary={isSummary}
                                      />
                                    </Heading>
                                  </div>
                                  <div className="pb-2">
                                    <Heading
                                      fontSize="12px"
                                      fontWeight="500"
                                      color="var(--deepBlue)"
                                      className="mb-0 pb-0 mt-0"
                                    >
                                      Clips Loading...
                                    </Heading>
                                    <img src={loading} className="loading" alt="loading" />
                                  </div>
                                </div>
                              </>
                            );
                          }
                          // return (
                          //   <AnalysisQuestion
                          //     questionObject={q}
                          //     question={q.questionName}
                          //     information={firstClipData?.answer.length + ' Videos'}
                          //     handleCopyClick={onCopyClick}
                          //     type="video"
                          //     description="Hits"
                          //     tag="Hits"
                          //     note="Uses cold air function to prevent heat damage"
                          //     key={q.question_ID}
                          //     cTag={cTag}
                          //     vType={vType}
                          //     qid={q.question_ID}
                          //     data={vType?.length > 0 ? hmlist : firstClipData?.answer}
                          //     isSummary={isSummary}
                          //     interviewsCompleted={interviewsCompleted}
                          //     preAnalysisData={summaryData}
                          //     preSelectedGenerateSummary={selectedGenerateSummary}
                          //     summaryWithQid={summaryWithQid}
                          //     setIsLoadingView={setIsLoadingView}
                          //     isLoadingView={isLoadingView}
                          //     isLoadingVideo={isLoadingVideo}
                          //   />
                          // );
                        }
                      }
                    }
                    if (q.questionType_ID === 10 && (dataType.length == 0 || dataType.indexOf('video') != -1)) {
                      return (
                        <AnalysisQuestion
                          questionObject={q}
                          question={q.questionName}
                          information={firstClipData ? firstClipData?.answer.length + ' Videos' : ''}
                          type="video"
                          description="Hits"
                          tag="Hits"
                          note="Uses cold air function to prevent heat damage"
                          key={q.question_ID}
                          cTag={cTag}
                          vType={vType}
                          qid={q.question_ID}
                          data={vType?.length > 0 ? hmlist : firstClipData?.answer}
                          isSummary={isSummary}
                          interviewsCompleted={interviewsCompleted}
                          preAnalysisData={summaryData}
                          preSelectedGenerateSummary={selectedGenerateSummary}
                          summaryWithQid={summaryWithQid}
                          setIsLoadingView={setIsLoadingView}
                          isLoadingView={isLoadingView}
                          isLoadingVideo={isLoadingVideo}
                          handleCopyClick={onCopyClick}
                        />
                      );
                    }

                    if (isGenerated && isGenerated[q.question_ID]) {
                      isSummary = isGenerated[q.question_ID][0].isSummary;
                    } else {
                      isSummary = 0;
                    }

                    if (
                      q.questionType_ID === 8 &&
                      q.answer !== null &&
                      (dataType.length == 0 || dataType.indexOf('images') != -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisQuestion
                          questionObject={q}
                          question={q.questionName}
                          information={q.answer.length + ' Photos'}
                          handleCopyClick={onCopyClick}
                          type="image"
                          data={q.answer}
                          // vType={vType}
                          key={'key_' + q.question_ID}
                          selectCheckBox={ev => {
                            console.log('ev', ev);
                          }}
                          qid={q.question_ID}
                        />
                      );
                    }
                    if (
                      q.questionType_ID === 12 &&
                      q.answer !== null &&
                      (dataType.length == 0 || dataType.indexOf('audios') != -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisQuestion
                          questionObject={q}
                          question={q.questionName}
                          information={q.answer.length + ' Audios'}
                          handleCopyClick={onCopyClick}
                          type="audio"
                          data={q.answer}
                          // vType={vType}
                          key={'key_' + q.question_ID}
                          selectCheckBox={ev => {
                            console.log('ev', ev);
                          }}
                          qid={q.question_ID}
                          isLoadingAudio={isLoadingAudio}
                          isSummary={isSummary}
                        />
                      );
                    }

                    if (
                      q.questionType_ID === 13 &&
                      q.answer.preAssingedImageUrl !== null &&
                      (dataType.length == 0 || dataType.indexOf('video') != -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisQuestion
                          questionObject={q}
                          question={q.questionName}
                          information={q.answer.length + ' Videos'}
                          handleCopyClick={onCopyClick}
                          type="video"
                          isMp4={true}
                          data={q.answer}
                          vType={vType}
                          key={'key_' + q.question_ID}
                          selectCheckBox={ev => {
                            console.log('ev', ev);
                          }}
                          qid={q.question_ID}
                          isLoadingAudio={isLoadingAudio}
                          isSummary={isSummary}
                          cTag={cTag}
                          interviewsCompleted={interviewsCompleted}
                          preAnalysisData={summaryData}
                          preSelectedGenerateSummary={selectedGenerateSummary}
                          summaryWithQid={summaryWithQid}
                          setIsLoadingView={setIsLoadingView}
                          isLoadingView={isLoadingView}
                          isLoadingVideo={isLoadingVideo}
                        />
                      );
                    }
                    if (
                      (q.questionType_ID === 2 ||
                        q.questionType_ID === 1 ||
                        q.questionType_ID === 7 ||
                        q.questionType_ID === 11) &&
                      q.answer !== null &&
                      (dataType.length == 0 || dataType.indexOf('bar_chart') != -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisBarChart
                          questionObject={q}
                          qid={q.question_ID}
                          question={q.questionName}
                          information="Bar Graph"
                          handleCopyClick={onCopyClick}
                          data={q.answerCnt}
                          options={dropDowns}
                          filters={filters}
                          key={'key_' + q.question_ID}
                          questionID={q.question_ID}
                        />
                      );
                    }
                    // if ((q.questionType_ID === 3 || q.questionType_ID === 4) && q.answerCnt != null || undefined && (dataType.length == 0 || dataType.indexOf('likert') != -1)) {
                    // 	return (
                    // 		<AnalysisLikert
                    // 			questionObject={q}
                    // 			question={q.questionName}
                    // 			information='Likert Graph'
                    // 			handleCopyClick={onCopyClick}
                    // 			dataset={q.answerCnt}
                    // 			qid={q.question_ID}
                    // 			key={'key_' + q.question_ID}
                    // 		/>
                    // 	)
                    // }
                    if (
                      (q.questionType_ID === 3 || q.questionType_ID === 4) &&
                      q.answerCnt != null &&
                      (dataType.length == 0 || dataType.indexOf('likert') != -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisLikert
                          questionObject={q}
                          question={q.questionName}
                          information="Likert Graph"
                          handleCopyClick={onCopyClick}
                          dataset={q.answerCnt}
                          qid={q.question_ID}
                          key={'key_' + q.question_ID}
                        />
                      );
                    }
                    if (
                      (q.questionType_ID === 5 || q.questionType_ID === 6) &&
                      q.answerCnt !== null &&
                      (dataType.length === 0 || dataType.indexOf('wcloud') !== -1) &&
                      vType.indexOf('Hits') === -1 &&
                      vType.indexOf('Misses') === -1
                    ) {
                      return (
                        <AnalysisWordCloud
                          questionObject={q}
                          qid={q.question_ID}
                          question={q.questionName}
                          information="Word Cloud"
                          handleCopyClick={onCopyClick}
                          dataset={q['questionAns']}
                          questionID={q.question_ID}
                          key={'key_' + q.question_ID}
                        />
                      );
                    }
                  }
                })}
              </div>
            </AnalysisListHeader>
          );
        })}
      </ListContainer>
      {/* <ListContainer>
				{getClipsData?.map((a, i) => {
					return (
						<AnalysisListHeader title={(i + 1).toString() + ' | ' + a.sectionName} key={i}>
							<div className='px-3' key={a.questionPaperSection_ID}>
								{a.questionName_array.map((q, j) => {
									if (q.questionType_ID === 10) {
										{
											console.log('inside video question new array', getClipsData);
											{
												return (
													<AnalysisQuestion
														questionObject={q}
														question={q.questionName}
														information={q.answer.length + ' Clips'}
														handleCopyClick={onCopyClick}
														type='video'
														data={q.answer}
														description='Hits'
														tag='Hits'
														note='Uses cold air function to prevent heat damage'
														key={'key_' + q.question_ID}
														cTag={cTag}
														vType={vType}
														qid={q.question_ID}
													/>
												)
											}


										}
									}

								})
								}
							</div>
						</AnalysisListHeader>
					)

				})}

			</ListContainer> */}
    </>
  );
}

export default AnalysisList;
