import React from 'react';
import video_play from '../../images/video_play.svg';
import analysis_video from '../../images/analysis_video.png';
import note_blue from '../../images/note_blue.svg';
import tag_grey from '../../images/tag_grey.svg';
import { Player, ControlBar, BigPlayButton } from 'video-react';

import styled from '@emotion/styled';
import { Heading } from '../../common';
import { Checkbox } from '../../components/form';
import useWindowDimensions from '../../common/useWindowDimensions';
const Container = styled.div`
  background: var(--midWhite);
  // margin: 0.5rem;
  margin-left: 0;
  // margin: 0.5rem 0;
  // margin-bottom: 1rem;
  // margin-right: 0.5rem;
  .image {
    // width: 14rem;
  }

  .video-react.video-react-fluid
  {
  padding-top : 70% !important}

  .video-react .video-react-video
  {
  height : 100%
  }
  label {
    margin-bottom: 0 !important;
  }
  .img {
    position: relative;
  }
  .video_play {
    position: absolute;
    left: 40%;
    top: 38%;
  }
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .imgContainer:hover {
      .popup {
        display: block;
      }
    }
    .imgContainer {
      position: relative;
      background: var(--darkWhite);
      margin-left: 5px;
      padding: 10px;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      .popup {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.18);
        border-radius: 5px;
        background: var(--white);
        font-size: 10px;
        font-weight: 500;
        color: var(--deepBlue);
        padding: 0.5rem;
        position: absolute;
        width: 160px;
        bottom: -40px;
        right: 0;
        z-index: 1080;
        display: none;
      }
    }
  }
`;
function AnalysisVideo({
  checkBoxValue,
  imgLink,
  tag,
  description,
  note,
  selectCheckBox,
  thumbLink,
  name,
  respondentID,
  button,
  vidId,
  selection,
  clipUpdateStatus,
  keepCheckBox = true
}) {
  const { height, width } = useWindowDimensions();
  return (
    <Container
      id={clipUpdateStatus}
       style={{ marginRight: width / 70 }}
    >
      {/* <div className='img'>
				<img src={video_play} className='video_play' />
				<img
					src={imgLink}
					className='image'
					style={{ width: '100%' }}
				/>
			</div> */}
      <div className="img">
        <Player
          // src={'https://maverickapi.estonetech.in/'+imgLink}
          src={imgLink}
          poster={thumbLink}
          preload="none"
          fluid= "false"
        >
          <BigPlayButton position="center" />
        </Player>
      </div>

      <div className="d-flex p-2 justify-content-between">
        <div className="d-flex align-items-center">
          {keepCheckBox ? (
            <Checkbox
              color="var(--deepBlue)"
              fontSize="10px"
              iconSize="10px"
              inactiveColor="var(--white)"
              value={vidId}
              checked={Object.keys(selection)?.indexOf(vidId?.toString()) != -1}
              onChange={ev => {
                selectCheckBox({
                  path: imgLink,
                  thumb: thumbLink,
                  name: name,
                  vidId: vidId,
                  qtype: 10
                });
              }}
            />
          ) : (
            ''
          )}
          <div className="pl-1">
            <Heading fontSize="10px" fontWeight="400" color="var(--deepBlue)" className="mb-0 pb-0">
              {name}
            </Heading>
            <Heading fontSize="10px" fontWeight="700" color="var(--deepBlue)" className="mb-0 pb-0">
              {button}
            </Heading>
          </div>
        </div>

        <div className="iconContainer">
          <div className="imgContainer">
            <img src={tag_grey} />
            {tag != null && tag != '' && <div className="popup">{tag}</div>}
          </div>
          <div className="imgContainer">
            <img src={note_blue} />
            {note != null && note != '' && <div className="popup">{note}</div>}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AnalysisVideo;
