import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DownIndicator from '../../common/DownIndicator';
import Question from '../../common/Question';
import QuickNote from '../../common/QuickNote';
import PlusGreen from '../../images/plus-green.svg';
import { Dropdown, InputText, Radio, TextArea, Checkbox } from '../../components/form';
import { Heading } from '../../common';
import Accordion from '../../common/Accordion';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { map } from 'jquery';
import { json } from 'd3';

const DownIndicatorCustom = ({ active, ...props }) => {
  return (
    <DownIndicator
      color={active ? 'var(--middleGreen)' : 'var(--middleGrey)'}
      iconStyle={{ fontSize: 16 }}
      {...props}
    />
  );
};

export default function GuidedScreen({
  step,
  callOn,
  id,
  respondantInfo,
  setSelectedOptionData,
  selectedOptionData,
  setSelectedTextValue,
  selectedTextValue,
  fullScreen,
  transcriptedData,
  scrollToBottom
}) {
  const [selectedOption, setSelectedOption] = useState('');
  const [dropDownData, setdropDownData] = useState([]);

  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  const [sectionData, setSectionData] = useState(captureguideData?.captureGuide[step - +1]);

  const projectRespondent_ID = localStorage.getItem('projectRespondent_ID') || '';

  useEffect(() => {}, [selectedOption]);
  useEffect(() => {}, [dropDownData]);
  useEffect(() => {
    setSectionData(captureguideData?.captureGuide[step - +1]);
  }, [step]);
  useEffect(() => {
    setSectionData(captureguideData?.captureGuide[step - +1]);
  }, [captureguideData]);

  useEffect(() => {
    scrollToBottom();
  }, [transcriptedData]);

  let dropDownQuestionType =
    (sectionData &&
      sectionData.questionName_array.map(item => {
        return (
          item.screenerQuestion &&
          item.screenerQuestion.map(data => {
            return { ...data, label: data.groupFilterLable, value: data.question_ID };
          })
        );
      })) ||
    [];

  const handleChange = e => {
    setdropDownData([...dropDownData, e]);
  };
  

  const { userId } = useSelector(state => state.LoginReducer);

  const { projectId } = useParams();
  const respondentUser_ID = respondantInfo?.Respondent_ID;

  const dispatch = useDispatch();
  const value = [];

  useEffect(() => {}, [selectedOptionData, selectedTextValue]);

  const saveQuestionAnswer = (e, question_ID, questionType_ID, questionLikertScale_ID) => {
    let payload = {};
    if (questionType_ID == 5 || questionType_ID == 6) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        answerText: e.target.value
      };
      setSelectedTextValue({
        ...selectedTextValue,
        [question_ID]: { question_ID: question_ID, answerText: e.target.value }
      });
    } else if (questionType_ID == 11) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionAnswer: e.target.value
      };
      setSelectedTextValue({
        ...selectedTextValue,
        [question_ID]: { question_ID: question_ID, questionAnswer: e.target.value }
      });
    } else if (questionType_ID == 1 || questionType_ID == 7) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: e
      };
      setSelectedOptionData({
        ...selectedOptionData,
        [question_ID]: { question_ID: question_ID, choiceName_ID: e, questionLikertScale_ID: questionLikertScale_ID }
      });
    } else if (questionType_ID == 4) {
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: e,
        questionLikertScale_ID: questionLikertScale_ID
      };
      setSelectedOptionData({
        ...selectedOptionData,
        [e]: { question_ID: question_ID, choiceName_ID: e, questionLikertScale_ID: questionLikertScale_ID }
      });
    } else if (questionType_ID == 2) {
      value.push(questionLikertScale_ID);
      payload = {
        user_ID: userId,
        projectId: id,
        respondentUser_ID: respondentUser_ID,
        question_ID: question_ID,
        questionType_ID: questionType_ID,
        questionChoice_ID: value
      };
      if (e.target.checked == true) {
        setSelectedOptionData({
          ...selectedOptionData,
          [questionLikertScale_ID]: {
            question_ID: question_ID,
            choiceName_ID: questionLikertScale_ID,
            questionLikertScale_ID: questionLikertScale_ID
          }
        });
      } else {
        for (let key in selectedOptionData) {
          if (key == questionLikertScale_ID) {
            delete selectedOptionData[key];
            setSelectedOptionData({ ...selectedOptionData });
          }
        }
      }
    } else if (questionType_ID == 8) {
      const formData = new FormData();
      const file = e.target.files[0];
      formData.append('user_ID', userId);
      formData.append('projectId', id);
      formData.append('respondentUser_ID', respondentUser_ID);
      formData.append('question_ID', question_ID);
      formData.append('questionType_ID', questionType_ID);
      formData.append('questionAnswerImage', file);
      console.log(formData);
      payload = formData;
    }

    dispatch({
      type: 'REQUEST_ADDRESPONDENTANSWER',
      payload: payload
    });
  };

  const likerTOptions = [
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Like very much'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Like'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Moderately Like'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Neutral'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Moderately Dislike'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Dislike'
    },
    {
      questionLikertScale_ID: null,
      questionLikertScaleName: 'Dislike Very Much'
    }
  ];

  const CustomTextArea = styled(TextArea)`
    // ::placeholder,
    // ::-webkit-input-placeholder,
    // ::-moz-placeholder,
    // :-ms-input-placeholder {
    // 	color: var(--grey20);
    // 	font-weight: 400;
    // 	font-size: 10px;
    // }
  `;

  return (
    <>
      {sectionData?.questionName_array.map((question, index) =>
        question.screenerQuestion ? (
          <Question key={index} question={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}

            <Dropdown
              selectedFontSize="0.75rem"
              options={dropDownQuestionType[index]}
              selectedColor="var(--deepBlue)"
              selectedFontWeight="400"
              controlStyle={{
                minHeight: 'auto',
                width: '180px',
                '&:hover': {
                  borderColor: 'var(--deepBlue)'
                }
              }}
              valueContainerStyle={{
                padding: 0
              }}
              DropdownIndicator={props => <DownIndicatorCustom active={callOn} {...props} />}
              selectedBorderBottom={callOn ? '1px solid var(--darkGreen)' : '1px solid var(--middleGrey)'}
              onChange={e => {
                handleChange(e);
              }}
              defaultValue={dropDownData[index]}
            />
          </Question>
        ) : question.questionType_ID == 10 ? (
          <Question question={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <QuickNote
              title={question.buttonName}
              badge={question.counter == 0 ? '' : question.counter}
              active={
                (callOn && selectedOption == '') ||
                (callOn && selectedOption?.toLowerCase() == question?.buttonName?.toLowerCase())
              }
              questionId={question.question_ID}
              questionPaperSection_ID={sectionData.questionPaperSection_ID}
              projectRepondentVideo_ID={captureguideData?.projectRepondentVideo_ID}
              projectRespondent_ID={projectRespondent_ID}
              setSelectedOption={setSelectedOption}
            />
          </Question>
        ) : question.questionType_ID == 11 ? (
          <Question key={index + question.questionName} question={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            {callOn && respondantInfo?.isInterviewStart == 1 ? (
              <InputText
                key={index + question.question_ID}
                type="number"
                showCharacters={false}
                fontSize="1.125rem"
                value={selectedTextValue[question.question_ID]?.questionAnswer}
                placeholder="Add a value"
                width="250px"
                style={{
                  color: 'var(--deepBlue)',
                  fontSize: '0.8rem',
                  fontWeight: '400'
                }}
                onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
              />
            ) : (
              <InputText
                key={index + question.question_ID}
                type="number"
                showCharacters={false}
                fontSize="1.125rem"
                value={selectedTextValue[question.question_ID]?.questionAnswer}
                disabled
                placeholder="Add a value"
                width="250px"
                style={{
                  color: 'var(--deepBlue)',
                  fontSize: '0.8rem',
                  fontWeight: '400'
                }}
                onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
              />
            )}
          </Question>
        ) : question.questionType_ID == 4 ? (
          <Accordion title={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex " style={{ flex: 0.8, justifyContent: 'space-around' }}>
                  <div className="d-flex text-center" style={{ flex: 0.8, justifyContent: 'space-between' }}>
                    {question.likertScaleName_array?.map((likerTOption, ltoIndex) => (
                      <div className="px-1 text-center">
                        <div style={{ height: 50 }}>
                          <Heading
                            type="6"
                            fontWeight="200"
                            color="var(--headingColor)"
                            className=""
                            fontSize="10px"
                            key={ltoIndex}
                          >
                            {likerTOption.questionLikertScaleName}
                          </Heading>
                        </div>
                        <div style={{ marginLeft: '15%' }}></div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* {question.choiceName_array?.map((opt, oIndex) => {
										return <>
											{
												<div className='d-flex ' style={{ flex: 0.8,justifyContent:'space-around',}}>
													
												<div className='d-flex text-center' style={{ flex: 0.8,marginLeft:'4%',justifyContent:'space-between' }}>
													{question.likertScaleName_array?.map((likerTOption, ltoIndex) => (
														<div className='px-1 text-start'>
															<div >
																</div>
																
																{callOn && respondantInfo?.isInterviewStart == 1 ?
																	<Radio
																		key={ltoIndex + likerTOption.questionLikertScale_ID}
																		id={likerTOption.questionLikertScale_ID}
																		name={question.questionName}
																		fontSize='0.875rem'
																		className='px-1 '
																		
																		checked={likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID}
																		//checked={respondantInfo?.isInterviewStart == 1 ? likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID : ""}
																		onChange={(e) => saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID, likerTOption.questionLikertScale_ID)} />
																	:
																	<Radio
																		key={ltoIndex + likerTOption.questionLikertScale_ID}
																		id={likerTOption.questionLikertScale_ID}
																		name={question.questionName}
																		fontSize='0.875rem'
																		checked=""
																		className='px-1'
																	/>
																}
														</div>
													))
													}
												</div>
											
											</div>
											}
											<div className='col-md-6 d-flex' key={oIndex}>
												<div className='' style={{ flex: 0.2, justifyContent: 'center', marginTop: -21 }}>
													<p type='6' fontWeight="200" color="var(--headingColor)" style={{fontSize:'10px'}}>
														{opt.choiceName}
													</p>
												</div>

											</div>
										</>
									})} */}
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex}>
                    <div className="d-flex" style={{ flex: 0.8, justifyContent: 'space-around' }}>
                      <div
                        className="d-flex text-center"
                        style={{ flex: 0.8, marginLeft: '4%', justifyContent: 'space-between' }}
                      >
                        {question.likertScaleName_array?.map((likerTOption, ltoIndex) => (
                          <div className="px-1 text-start" key={ltoIndex + likerTOption.questionLikertScale_ID}>
                            <div></div>
                            {callOn && respondantInfo?.isInterviewStart == 1 ? (
                              <Radio
                                id={likerTOption.questionLikertScale_ID}
                                name={question.questionName + oIndex} // Use a unique name for each set of likert scale options
                                fontSize="0.875rem"
                                className="px-1"
                                checked={
                                  likerTOption.questionLikertScale_ID ===
                                  (selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID || '')
                                }
                                onChange={e =>
                                  saveQuestionAnswer(
                                    opt.choiceName_ID,
                                    question.question_ID,
                                    question.questionType_ID,
                                    likerTOption.questionLikertScale_ID
                                  )
                                }
                              />
                            ) : (
                              <Radio
                                id={likerTOption.questionLikertScale_ID}
                                name={question.questionName + oIndex}
                                fontSize="0.875rem"
                                checked=""
                                className="px-1"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 d-flex" key={oIndex}>
                      <div className="" style={{ flex: 0.2, justifyContent: 'center', marginTop: -21 }}>
                        <p type="6" fontWeight="200" color="var(--headingColor)" style={{ fontSize: '10px' }}>
                          {opt.choiceName}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="row">
								<div className='col-md-12'>
								
								<div className='d-flex' style={{ flex: 0.8, marginLeft: "15%",justifyContent:'space-around' }}>
														{question.likertScaleName_array?.map((likerTOptions,ltoIndex)=>(
															<Heading type="6" fontWeight="100" color="var(==headingColor)" className="" fontSize="10px" key={ltoIndex}>
																{likerTOptions.questionLikertScaleName}
															</Heading>))}
													</div>
									{question.choiceName_array?.map((opt, oIndex) => {
										return <>
											
											<div className='d-flex mr-3' style={{ flex: 0.8, marginLeft: "15%",justifyContent:'space-around' }}>
													
														<div className='d-flex ' style={{ flex: 0.8}}>
															{question.likertScaleName_array?.map((likerTOption, ltoIndex) => (
																<div className='mr-4'>
																	<div style={{ height: 50 }}>
																		</div>
																		<div className='d-flex mr-4' style={{ flex: 0.8, marginLeft: "32%" ,}}>
																		{callOn && respondantInfo?.isInterviewStart == 1 ?
																			<Radio
																				key={ltoIndex + likerTOption.questionLikertScale_ID}
																				id={likerTOption.questionLikertScale_ID}
																				name={question.questionName}
																				fontSize='0.875rem'
																				
																				checked={likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID}
																				//checked={respondantInfo?.isInterviewStart == 1 ? likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID : ""}
																				onChange={(e) => saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID, likerTOption.questionLikertScale_ID)} />
																			:
																			<Radio
																				key={ltoIndex + likerTOption.questionLikertScale_ID}
																				id={likerTOption.questionLikertScale_ID}
																				name={question.questionName}
																				fontSize='0.875rem'
																				checked=""
																				
																			/>
																		}</div>
																</div>
															))
															}
														</div>
													
													</div>
												
											
											<div className='col-md-12 d-flex' key={oIndex}>
												<div className='' style={{ flex: 0.2, justifyContent: 'center', marginTop: -21 }}>
													<p type='6' fontWeight="200" color="var(--headingColor)" style={{fontSize:'10px'}}>
														{opt.choiceName}
													</p>
												</div>

											</div>
										</>
									})}
								</div>
							</div>
							{/* <div className="row">
								<div className='col-md-12'>
								
								<div className='d-flex' style={{ flex: 0.8, marginLeft: "15%",justifyContent:'space-around' }}>
														{question.likertScaleName_array?.map((likerTOptions,ltoIndex)=>(
															<Heading type="6" fontWeight="100" color="var(==headingColor)" className="" fontSize="10px" key={ltoIndex}>
																{likerTOptions.questionLikertScaleName}
															</Heading>))}
													</div>
									{question.choiceName_array?.map((opt, oIndex) => {
										return <>
											
											<div className='d-flex mr-3' style={{ flex: 0.8, marginLeft: "15%",justifyContent:'space-around' }}>
													
														<div className='d-flex ' style={{ flex: 0.8}}>
															{question.likertScaleName_array?.map((likerTOption, ltoIndex) => (
																<div className='mr-4'>
																	<div style={{ height: 50 }}>
																		</div>
																		<div className='d-flex mr-4' style={{ flex: 0.8, marginLeft: "32%" ,}}>
																		{callOn && respondantInfo?.isInterviewStart == 1 ?
																			<Radio
																				key={ltoIndex + likerTOption.questionLikertScale_ID}
																				id={likerTOption.questionLikertScale_ID}
																				name={question.questionName}
																				fontSize='0.875rem'
																				
																				checked={likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID}
																				//checked={respondantInfo?.isInterviewStart == 1 ? likerTOption.questionLikertScale_ID == selectedOptionData[opt.choiceName_ID]?.questionLikertScale_ID : ""}
																				onChange={(e) => saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID, likerTOption.questionLikertScale_ID)} />
																			:
																			<Radio
																				key={ltoIndex + likerTOption.questionLikertScale_ID}
																				id={likerTOption.questionLikertScale_ID}
																				name={question.questionName}
																				fontSize='0.875rem'
																				checked=""
																				
																			/>
																		}</div>
																</div>
															))
															}
														</div>
													
													</div>
												
											
											<div className='col-md-12 d-flex' key={oIndex}>
												<div className='' style={{ flex: 0.2, justifyContent: 'center', marginTop: -27 }}>
													<p type='6' fontWeight="200" color="var(--headingColor)" style={{fontSize:'10px'}}>
														{opt.choiceName}
													</p>
												</div>

											</div>
										</>
									})}
								</div>
							</div> */}
          </Accordion>
        ) : question.questionType_ID == 1 ? (
          <Accordion title={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Radio
                        name={question.questionName}
                        id={oIndex}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        // checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        checked={opt.choiceName_ID == selectedOptionData[question.question_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID)
                        }
                      >
                        {opt.choiceName}
                      </Radio>
                    ) : (
                      <Radio
                        name={question.questionName}
                        id={oIndex}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Radio>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 5 || question.questionType_ID == 6 ? (
          <Question question={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {callOn && respondantInfo?.isInterviewStart == 1 ? (
                  <InputText
                    key={index + question.question_ID}
                    fontSize="0.75rem"
                    showCharacters={false}
                    placeholder="Add a description"
                    className="mt-1"
                    width={'400px'}
                    value={selectedTextValue[question.question_ID]?.answerText}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                ) : (
                  <InputText
                    key={index + question.question_ID}
                    fontSize="0.75rem"
                    showCharacters={false}
                    placeholder="Add a description"
                    className="mt-1"
                    disabled
                    width={'400px'}
                    value={selectedTextValue[question.question_ID]?.answerText}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                )}
              </div>
            </div>
          </Question>
        ) : question.questionType_ID == 2 ? (
          <Accordion className=" py-2 " title={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Checkbox
                        id={oIndex}
                        fontSize="0.875rem"
                        className="mb-0 pb-0"
                        checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(e, question.question_ID, question.questionType_ID, opt.choiceName_ID)
                        }
                      >
                        {opt.choiceName}
                      </Checkbox>
                    ) : (
                      <Checkbox
                        id={oIndex}
                        fontSize="0.875rem"
                        className="mb-0 pb-0"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Checkbox>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 7 ? (
          <Accordion title={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                {question.choiceName_array?.map((opt, oIndex) => (
                  <div key={oIndex} className={`col-md-12 d-flex align-items-center my-1`}>
                    {callOn && respondantInfo?.isInterviewStart == 1 ? (
                      <Radio
                        id={oIndex}
                        name={question.questionName}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        // checked={opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID}
                        checked={opt.choiceName_ID == selectedOptionData[question.question_ID]?.choiceName_ID}
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                        onChange={e =>
                          saveQuestionAnswer(opt.choiceName_ID, question.question_ID, question.questionType_ID)
                        }
                      >
                        {opt.choiceName}
                      </Radio>
                    ) : (
                      <Radio
                        id={oIndex}
                        name={question.questionName}
                        className="mb-0 pb-0"
                        fontSize="0.875rem"
                        checked=""
                        // checked={respondantInfo?.isInterviewStart == 1 ? opt.choiceName_ID == selectedOptionData[opt.choiceName_ID]?.choiceName_ID : ""}
                      >
                        {opt.choiceName}
                      </Radio>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Accordion>
        ) : question.questionType_ID == 8 ? (
          <Question question={question.questionName}>
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-md-12">
                <img src={PlusGreen} alt="Plus Green" className="plus-green mr-2" style={{ width: 15 }} />
                <label style={{ fontWeight: 600, fontSize: 12, color: 'var(--snapGreen)', cursor: 'pointer' }}>
                  Select a file to upload
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => saveQuestionAnswer(e, question.question_ID, question.questionType_ID)}
                  />
                </label>
              </div>
            </div>
          </Question>
        ) : (
          <Question question={question.questionName}>
            {' '}
            {question.questionDescription !== 'null' && question.questionDescription?.length > 0 ? (
              <h1 color="var(--deepBlue)" font-size="0.75rem" font-weight="500" class="m-heading  css-1m69ccm">
                {'Note: ' + question.questionDescription}{' '}
              </h1>
            ) : (
              ''
            )}
          </Question>
        )
      )}
      {step == 2000 && transcriptedData?.length > 0 && (
        <>
          <div>
            {transcriptedData.map((item, index) => (
              <div key={index} className="d-flex mt-2 w-100 border-bottom" style={{ fontSize: '14px' }}>
                {item && item.text != '' && (
                  <>
                    <div className="p-2 w-25" style={{ color: '#38454F', width: '15%', fontWeight: '600' }}>
                      {item.name + ' :'}
                    </div>
                    <div className="p-2 w-100" style={{ color: '#38454F' }}>
                      {item.text}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
