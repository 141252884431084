import {
  PRE_PROJECT_DATA_RECIEVED,
  RECIEVED_GET_CRITERIA_AND_BUDGET,
  RECIEVED_GET_RESOLUTION,
  RECIEVED_GET_PARTICIPANTS_LIST,
  SEND_PARTICIPANTS_LIST_RECIEVED_ONEND,
  PROJECT_GROUP_RECEIVED,
  RECIEVED_RESUME_DATA,
  RECIEVED_SCHEDULE_CALL,
  RECIEVED_VIDEO_RECORDING,
  RECIEVED_VIDEO_RECORDING_RESOURCE,
  RECIEVED_QUERY_VIDEO_RECORDING,
  RECIEVED_UPDATELAYOUT_VIDEO_RECORDING,
  RECIEVED_STOP_VIDEO_RECORDING,
  RECIEVED_VIDEO_USERLIST,
  GETUIDDETAILS_RECEIVED,
  RECIEVED_VIDEO_RECORDING_UPDATE,
  RECIEVED_RTC_TOKEN_BUILDER,
  RTM_TRANSCRIPT_DATA,
  SAVE_RTM_TRANSCRIPT_DATA,
  STORE_RTM_TRANSCRIPT_LOGS,
  GET_RTM_TRANSCRIPT_DATA,
  UPDATE_RTM_TRANSCRIPT_DATA,
  UPDATE_GUEST_DATA,
  RECIEVED_ADD_SECTION,
  RECIEVED_DRAG_QUESTION,
  CLEAR_ADD_SECTION,
  RECIEVED_EDIT_SECTION,
  CLEAR_EDIT_SECTION,
  STORE_RTM_TRANSCRIPT_MESSAGE_LOGS,
  CLEAR_CRITERIS_AND_BUDGET_STATUS,
  UPDATE_SID_DATA
} from '../../constants/projects/ProjectConstants';
import actions from '../../actions';

import ReactDOM from 'react-dom';
import { getParticipants } from '../../actions/project';

const INITL_STATE = {
  preSetupData: [],
  resumeData: [],
  schedulecall: [],
  resource_Id: [],
  resource_Data: [],
  Sid: [],
  stop: [],
  updateLayout: [],
  criteriaAndBudget: null,
  getResolution: null,
  getParticipant: null,
  SendParticipant: null,
  GetcriteriaAndBudgetScreener: [],
  questionTypes: [],
  project_ID: null,
  addProjectResponse: null,
  projectIdentity: null,
  updateRecording: null,
  rtcTokenBuilder: null,
  rtmTranscriptData: null,
  saveRtmTranscriptData: null,
  storeTranscriptLogs: null,
  getRtmTranscriptData: null,
  updateRtmTranscriptData: null,
  updateGuestData: null,
  addSection: null,
  editSection: null,
  dragQuestion: null,
  storeTranscriptMessageLogs: null,
  criteriaAndBudgetStatus: null,
  sidUpdate: null
};

const ProjectReducer = (state = INITL_STATE, action) => {
  switch (action.type) {
    case PRE_PROJECT_DATA_RECIEVED:
      return {
        ...state,
        preSetupData: action.payload
      };

    case UPDATE_SID_DATA:
      return {
        ...state,
        sidUpdate: action.payload
      };

    case RECIEVED_GET_CRITERIA_AND_BUDGET:
      return {
        ...state,
        criteriaAndBudget: action.payload.Response,
        GetcriteriaAndBudgetScreener: action.payload.Response.Getcriteria_and_budget_screener,
        questionTypes: action.payload.Response.questionType,
        project_ID: action.payload.project_ID,
        projectIdentity: action.payload.projectIdentity,
        criteriaAndBudgetStatus: action.payload.Status.StatusCode
      };
    case CLEAR_CRITERIS_AND_BUDGET_STATUS:
      return {
        ...state,
        criteriaAndBudgetStatus: 0
      };
    case RECIEVED_GET_RESOLUTION:
      return {
        ...state,
        getResolution: action.payload
      };

      case RECIEVED_GET_PARTICIPANTS_LIST:
      return {
        ...state,
        getParticipant: action.payload
        
      };
      case SEND_PARTICIPANTS_LIST_RECIEVED_ONEND:
        return {
          ...state,
          SendParticipant: action.payload
        };

    case PROJECT_GROUP_RECEIVED:
      return {
        ...state,
        addProjectResponse: action.data
      };
    case RECIEVED_RESUME_DATA:
      return {
        ...state,
        resumeData: action.payload
      };

    case RECIEVED_SCHEDULE_CALL:
      return {
        ...state,
        schedulecall: action.payload
      };

    case RECIEVED_VIDEO_RECORDING:
      return {
        ...state,
        resource_Id: action.payload.resourceId
      };
    case RECIEVED_VIDEO_RECORDING_RESOURCE:
      return {
        ...state,
        resource_Data: action.payload
      };

    case RECIEVED_QUERY_VIDEO_RECORDING:
      return {
        ...state,
        updateLayout: action.payload
      };

    case RECIEVED_UPDATELAYOUT_VIDEO_RECORDING:
      return {
        ...state,
        Sid: action.payload
      };

    case RECIEVED_STOP_VIDEO_RECORDING:
      return {
        ...state,
        stop: action.payload
      };

    case RECIEVED_VIDEO_USERLIST:
      console.log('sid', action.payload);
      return {
        ...state,
        userList_Data: action.payload
      };

    case GETUIDDETAILS_RECEIVED:
      console.log('GETUIDDETAILS_RECEIVED', action.payload);
      return {
        ...state,
        getUIDData: action.payload
      };

    case RECIEVED_VIDEO_RECORDING_UPDATE:
      console.log('sid', action.payload);
      return {
        ...state,
        updateRecording: action.payload
      };

    case RECIEVED_RTC_TOKEN_BUILDER:
      console.log('token', action.payload);
      return {
        ...state,
        rtcTokenBuilder: action.payload
      };
    case RTM_TRANSCRIPT_DATA:
      console.log('token', action.payload);
      return {
        ...state,
        rtmTranscriptData: action.payload
      };
    case SAVE_RTM_TRANSCRIPT_DATA:
      console.log('token transcript', action.payload);
      return {
        ...state,
        saveRtmTranscriptData: action.payload
      };

    case STORE_RTM_TRANSCRIPT_LOGS:
      console.log('token transcript', action.payload);
      return {
        ...state,
        storeTranscriptLogs: action.payload
      };

    case GET_RTM_TRANSCRIPT_DATA:
      console.log('token transcript', action.payload);
      return {
        ...state,
        getRtmTranscriptData: action.payload
      };

    case UPDATE_RTM_TRANSCRIPT_DATA:
      console.log('update transcript', action.payload);
      return {
        ...state,
        updateRtmTranscriptData: action.payload
      };

    case UPDATE_GUEST_DATA:
      // console.log('update transcript', action.payload);
      return {
        ...state,
        updateGuestData: action.payload
      };

    case STORE_RTM_TRANSCRIPT_MESSAGE_LOGS:
      console.log('token transcript', action.payload);
      return {
        ...state,
        storeTranscriptMessageLogs: action.payload
      };

    case RECIEVED_ADD_SECTION:
      //console.log('add section of index.js', action.payload.data.Status);
      return {
        ...state,
        addSection: action.payload.data.Status
      };

    case RECIEVED_DRAG_QUESTION:
      //console.log('add section of index.js', action.payload.data.Status);
      return {
        ...state,
        dragQuestion: action.payload.data.Status
      };

    case CLEAR_ADD_SECTION:
      // console.log('delete section of index.js');
      return {
        ...state,
        addSection: 0
      };

    case RECIEVED_EDIT_SECTION:
      //console.log('EDIT section of index.js', action.payload.data.Status);
      return {
        ...state,
        editSection: action.payload.data.Status
      };

    case CLEAR_EDIT_SECTION:
      //  console.log('delete section 2 of index.js');
      return {
        ...state,
        editSection: 0
      };

    default:
      return state;
  }
};

export default ProjectReducer;
