import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import styled from '@emotion/styled';
import { Button, Heading, Card, Text } from '../../common';
import { FormGroup, Label, InputText, InputNumber, ButtonRadio } from '../../components/form';
import sign_in_background from '../../assets/images/sign_in_background.jpg';
import RightTick from '../../images/right-tick.svg';
import WrongTick from '../../images/wrong-tick.svg';
import { REQUEST_SUPERADMINDASHBOARD_DATA } from '../../constants/SuperAdmin/SuperAdminDashboardConstants';
import { REQUEST_OTP } from '../../constants/otp/OtpConstants';
import maverickPortalhttp from '../../utils/webService/webService';
import maverickPortalApiEndPoint from '../../config/apiEndPoint';
import AuthService from '../../utils/authService/AuthService';
import validator from 'validator';
import '../../App.css';

const SignInSection = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 1rem;
  .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const SignInCard = styled(Card)`
  width: 580px;
  border: 1px solid var(--borderColor);
  padding: 1.8rem 2.75rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #898989;
`;

const SmallLinkButton = styled(Button)`
  font-size: 13px;
  padding-right: 0;
  font-weight: 500;
  color: var(--labelColor);
`;

const plus = {
  float: 'right',
  position: 'relative',
  margin: '-5%'
};

function SignInScreen(props) {
  const [usertype, setUsertype] = useState('respondent');

  const [email, setEmail] = useState('');

  const [mobile, setMobile] = useState('');

  const [password, setPassword] = useState('');

  const [checkBox, setCheckBox] = useState('');

  const [loginStatus, setloginStatus] = useState('');

  const [loginStatusOnReset, setloginStatusOnReset] = useState('');

  const [hovered, setHovered] = useState(false);

  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState('');
  const [onChangeText, setOnChangeText] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setloginStatus('');
    }, 5000);
  },[loginStatus])

  const validateEmail = e => {
    setOnChangeText(e);
    var email = e;
    if (validator.isEmail(email)) {
      setEmailError('Valid Email');
      setEmail(email);
    } else {
      setEmailError('invalid');
      setEmail('');
    }
  };

  const handleHover = () => {
    setHovered(true);
    console.log('yes');
  };

  function handleKeyDown(event) {
    const allowedKeys = [
      8,
      46,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      106
    ];

    if (!allowedKeys.includes(event.keyCode)) {
      event.preventDefault();
    }
  }

  const loginStatusOnSignIN = () => {
    const data = {
      email: email,
      password: password,
      mobile: mobile,
      role_ID: usertype === 'researcher' ? 1 : 4
    };

    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.login.url, maverickPortalApiEndPoint.login.methodType, data)
      .then(response => {
        if (response.data.Response !== undefined && response.data.Response?.token_data.access_token.length) {
          AuthService.login(response?.data?.Response);
        } else if (response.data?.Status?.StatusCode == -2) {
          localStorage.removeItem('token');
          localStorage.setItem(response.data?.Status?.StatusDesc);
          history.push('/#/sign-in');
          console.log(response.data?.Status?.StatusDesc);
          // resolve({ res });
        } else {
          console.log(response.data?.Status?.StatusDesc);
        }
        const res = response.data;
        if (res) {
          setloginStatus(res?.Status?.StatusDesc);
          // setloginStatusOnReset(res.Status.StatusDesc)
        }
      });
  };

  const sendOTP = () => {
    dispatch({
      type: REQUEST_OTP,
      payload: {
        email: email
      }
    });
  };
  return (
    <SignInSection className="sign-in-section">
      <BackgroundImage
        src={sign_in_background}
        alt="Maverick running on Different Mobiles"
        className="sign-in-section-img"
      />

      <div className="container-fluid">
        <SignInCard className="card sign-in-card mt-5">
          <form className="sign-in-form">
            <Heading color="var(--seaBlue)">Sign in to Explorastory</Heading>

            <div className="mt-4 mb-4">
              {/* <ButtonRadio
                text="Researcher"
                className='mr-3'
                name="user-type"
                value='researcher'
                onClick={()=> {setUsertype('researcher'), setMobile(''), setloginStatus('')}}
                checked={usertype === 'researcher'}
                onClick={() => {setUsertype('researcher'), setMobile(''), setloginStatus('')}}
              /> */}
              {/* <ButtonRadio
                text="Observer"
                className="mr-3"
                name="user-type"
                value="observer"
                checked={usertype === 'observer'}
                onClick={() => setUsertype('observer')}
              /> */}
              {/* <ButtonRadio 
                text="Admin"
                className='mr-3'
                name="user-type"
                value='admin'
                
                checked={usertype === 'admin'}
                onClick={() => setUsertype('admin')}
              /> */}
              {/* <ButtonRadio
                text="Respondent"
                className='mr-3'
                name="user-type"
                value='respondent'
               
                checked={usertype === 'respondent'}
                onClick={() => {setUsertype('respondent'), setEmail(''), setloginStatus('')}}
              /> */}
              I am
              <div style={{ display: 'flex' }}>
                <div
                  onClick={() => {
                    setUsertype('respondent'), setEmail(''), setloginStatus('');
                  }}
                >
                  <input
                    type="radio"
                    id="respondent"
                    name="login_role"
                    value="Respondent"
                    defaultChecked
                    style={{ cursor: 'pointer', accentColor: '#728f01' }}
                  />
                  <label for="respondent" className="pl-1" style={{ cursor: 'pointer' }}>
                    Respondent
                  </label>
                </div>
                <div
                  style={{ marginLeft: '10%' }}
                  onClick={() => {
                    setUsertype('researcher'), setMobile(''), setloginStatus('');
                  }}
                >
                  <input
                    type="radio"
                    id="researcher"
                    name="login_role"
                    value="Researcher"
                    style={{ cursor: 'pointer', accentColor: '#728f01' }}
                  />
                  <label for="researcher" className="pl-1" style={{ cursor: 'pointer' }}>
                    Researcher
                  </label>
                </div>
                <div
                  style={{ marginLeft: '10%' }}
                  onClick={() => {
                    setUsertype('researcher'), setMobile(''), setloginStatus('');
                  }}
                >
                  <input
                    type="radio"
                    id="admin"
                    name="login_role"
                    value="Admin"
                    style={{ cursor: 'pointer', accentColor: '#728f01' }}
                  />
                  <label for="admin" className="pl-1" style={{ cursor: 'pointer' }}>
                    Admin
                  </label>
                </div>
              </div>
            </div>

            <FormGroup className="mb-4">
              {usertype === 'researcher' ? (
                <>
                  <StyledLabel>Email</StyledLabel>
                  <InputText
                    type="text"
                    value={email}
                    onChange={e => validateEmail(e.target.value)}
                    showCharacters={false}
                    className="form-control"
                    id="email"
                    placeholder="you@example.com"
                  />
                  {emailError === 'invalid' && onChangeText != '' && (
                    <p
                      style={{
                        fontweight: '300px',
                        color: 'var(--errorColor)',
                        fontSize: '0.75rem',
                        fontFamily: 'Noto Sans'
                      }}
                    >
                      Please enter a valid email
                    </p>
                  )}
                </>
              ) : null}
              {usertype === 'respondent' ? (
                <>
                  <StyledLabel>Phone Number</StyledLabel>
                  <InputText
                    type="text"
                    value={mobile}
                    onChange={e => setMobile(e.target.value)}
                    showCharacters={false}
                    className="form-control"
                    id="mobile"
                    maxLength={10}
                    placeholder="9898989898"
                    onKeyDown={handleKeyDown}
                  />
                </>
              ) : null}

              {loginStatus == 'Invalid User' ? (
                <img src={WrongTick} alt="Wrong Tick" className="wrong-tick" style={plus} />
              ) : null}
              {loginStatusOnReset == 'Invalid User' ? (
                <img src={WrongTick} alt="Wrong Tick" className="wrong-tick" style={plus} />
              ) : null}
              {loginStatus == 'Password Do Not Match' ? (
                <img src={RightTick} alt="Right Tick" className="right-tick" style={plus} />
              ) : null}
              {usertype === 'researcher' ? (
                <>
                  {loginStatus == 'Invalid User' ? <p style={{ color: 'red' }}>Invalid Email id</p> : null}
                  {loginStatusOnReset == 'Invalid User' ? <p style={{ color: 'red' }}>Invalid Email id</p> : null}
                </>
              ) : null}
              {usertype === 'respondent' ? (
                <>
                  {loginStatus == 'Invalid User' ? <p style={{ color: 'red' }}>Invalid user</p> : null}
                  {loginStatusOnReset == 'Invalid User' ? <p style={{ color: 'red' }}>Invalid User</p> : null}
                </>
              ) : null}
            </FormGroup>

            <FormGroup className="form-group mb-2">
              <div className="d-flex justify-content-between">
                <StyledLabel>Password</StyledLabel>
              </div>

              <InputText
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                showCharacters={false}
                className="form-control"
                id="password"
                placeholder="*******"
              />
              {loginStatus == 'Password Do Not Match' ? (
                <img src={WrongTick} alt="Wrong Tick" className="wrong-tick" style={plus} />
              ) : null}
              <div style={{ display: 'flex' }}>
                {loginStatus == 'Password Do Not Match' ? <p style={{ color: 'red' }}>Invalid Password</p> : null}
                {!email ? (
                  <p
                    onMouseOver={handleHover}
                    className="underline"
                    tabIndex="-1"
                    style={{ float: 'right', marginLeft: 'auto', marginBottom: 0 }}
                  >
                    <Text type="2">Reset Password</Text>
                  </p>
                ) : (
                  <Link
                    to={`/verify-code/${email}`}
                    className="underline"
                    tabIndex="-1"
                    style={{ float: 'right', marginLeft: 'auto' }}
                    onClick={sendOTP}
                  >
                    <Text type="2">Reset Password</Text>
                  </Link>
                )}
              </div>
            </FormGroup>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {usertype === 'respondent' ? (
                <>
                  <text
                    className="mt-1"
                    style={{ fontSize: '10px', fontWeight: 'lighter', color: 'var(--textColor)', display: 'flex' }}
                  >
                    <input
                      type="checkbox"
                      name="T&C"
                      onChange={e => setCheckBox(e.target.checked)}
                      style={{ cursor: 'pointer' }}
                    />{' '}
                    <span>
                      {' '}
                      I've read and agree to the respondent
                      <a href="https://www.humanifytechnologies.com/respondent-terms-and-condition" target="blank">
                        {' '}
                        Terms and Condition{' '}
                      </a>{' '}
                      &{' '}
                      <a href="https://www.humanifytechnologies.com/respondent-privacy-policy" target="blank">
                        {' '}
                        Privacy Policy{' '}
                      </a>
                    </span>
                  </text>
                  <br></br>
                </>
              ) : null}
              {usertype === 'researcher' ? (
                <>
                  <text
                    className="mt-1"
                    style={{ fontSize: '10px', fontWeight: 'lighter', color: 'var(--textColor)', display: 'flex' }}
                  >
                    <input
                      type="checkbox"
                      name="T&C"
                      onChange={e => setCheckBox(e.target.checked)}
                      style={{ cursor: 'pointer' }}
                    />{' '}
                    <span>
                      {' '}
                      I've read and agree to the researcher
                      <a href="https://www.humanifytechnologies.com/researcher-terms-and-condition" target="blank">
                        {' '}
                        Terms and Condition{' '}
                      </a>{' '}
                      &{' '}
                      <a href="https://www.humanifytechnologies.com/researcher-privacy-policy" target="blank">
                        {' '}
                        Privacy Policy{' '}
                      </a>
                    </span>
                  </text>
                  <br></br>
                </>
              ) : null}
            </div>
            <text style={{ fontSize: '10px', fontWeight: '100', color: 'var(--textColor)' }}>
              Problem Signing in? Contact us at{' '}
              <span className="underline" style={{ fontWeight: 'bold' }}>
                hello@explorastory.in
              </span>
            </text>

            <div className="form-group mt-2">
              {/* <Button type="submit" loading={false}>Sign In</Button> */}

              <Button
                disabled={(!email && !mobile) || !password || !checkBox}
                fontSize="1.125rem"
                size="mid"
                onClick={() => {
                  localStorage.setItem('user', usertype);
                  dispatch({
                    type: REQUEST_SUPERADMINDASHBOARD_DATA
                  });
                  dispatch({
                    type: 'REQUEST_USER_LOGIN',
                    payload: {
                      email: email,
                      password: password,
                      mobile: mobile,
                      role_ID: usertype === 'researcher' ? 1 : 4
                    }
                  });
                  loginStatusOnSignIN();
                }}
              >
                Sign In
              </Button>
            </div>
          </form>
        </SignInCard>
      </div>
    </SignInSection>
  );
}

const mapStateToProps = state => {
  return {
    // will be available as props.captureData
    status: state.LoginReducer.status
  };
};

// export default EditTab;
export default connect(mapStateToProps)(SignInScreen);
