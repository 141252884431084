import actions from '../../actions';
import { NEW_PROJECT_TASK_RECIEVED } from '../../constants/projects/ProjectConstants';

// reducer with initial state
const initialState = {
    questionList: [],
    Referral_codes: null
};

const reducer = (state = initialState, action = {}) => {
    
    
    switch (action.type) {
      
        case actions.QuestionActions.REQUEST_CREATE_PROJECT:
            return {...state, isloggedIn: false };
        case actions.QuestionActions.CREATE_PROJECT_RECEIVED:
         
            return {
                ...state,
                isloggedIn: true,
                questionList: action.data,
                Referral_codes: action.data.Referral_codes
            };
        case actions.QuestionActions.REQUEST_EDIT_PROJECT:
           
            return {...state, isloggedIn: false ,
                Referral_codes: action.data 
            };


        case actions.QuestionActions.REQUEST_DELETE_REFERRAL:
            return {
                ...state,
               
            };

            case actions.QuestionActions.DELETE_REFERRAL_RECEIVED:
            return {...state};

        case actions.QuestionActions.EDIT_PROJECT_RECEIVED:
      
            return {
                ...state,
                isloggedIn: true,
                questionList: action.data,
                Referral_codes: action.data.Referral_codes
            };

        case  actions.QuestionActions.CLEAR_EDIT_PROJECT:
         
                return {
                  ...state,
                  Referral_codes: null
                };   

                

        // case 'CANCEL_UPDATE':
        //     return{
        //         ...state,
        //         isloggedIn: true,
        //         questionList: action.data
        //     }
        case NEW_PROJECT_TASK_RECIEVED:
            //check exist
            var mainTaskArray = [];
            const foundData = state.questionList.projectTask_Data.some(task => {
                return task.projectTask_ID === action.payload.projectTask_ID
            });
            if (!foundData) 
            {
                var type = ''
                if (action.payload.taskType === 1) 
                {
                    type = "Form"
                } 
                else if (action.payload.taskType === 2) 
                {
                    type = "Video"
                } 
                else 
                {
                    type = action.payload.taskType
                }

                mainTaskArray = [...state.questionList.projectTask_Data, {...action.payload, taskType: type }];
            } 
            else 
            {
                const newTaskArray = state.questionList.projectTask_Data.map((task, ind) => {
                    if (task.projectTask_ID != action.payload.projectTask_ID) 
                    {
                        return {
                            ...state.questionList.projectTask_Data[ind],
                        }
                    } 
                    else 
                    {
                        return {
                            ...action.payload
                        }
                    }
                });
                mainTaskArray = [...newTaskArray];
            }

            return {
                ...state,
                questionList: {
                    ...state.questionList,
                    projectTask_Data: [
                        ...mainTaskArray
                    ]
                }
            }
        default:
            return state;
    }
};

export default reducer;