import {
  PRE_PROJECT_DATA,
  PRE_PROJECT_DATA_RECIEVED,
  DELETE_PROJECT_IMAGE,
  DELETE_PROJECT_TASK,
  EDIT_PROJECT_TASK,
  CREATE_PROJECT_LEAD,
  ADD_PROJECT_RESEARCHER,
  ADD_PROJECT_OBSERVER,
  GET_CRITERIA_AND_BUDGET,
  RECIEVED_GET_CRITERIA_AND_BUDGET,
  UPDATE_STAGE,
  GET_RESOLUTION,
  RECIEVED_GET_RESOLUTION,
  REMOVE_VIDEO_CALL,
  RECIEVED_REMOVE_VIDEO_CALL,
  EDIT_SECTION,
  RECIEVED_EDIT_SECTION,
  ADD_SECTION,
  RECIEVED_ADD_SECTION,
  DRAG_QUESTION,
  RECIEVED_DRAG_QUESTION,
  COUNT_NO_OF_PARTICIPANTS,
  RECIEVED_COUNT_NO_OF_PARTICIPANTS,
  VIDEO_RECORDING,
  RECIEVED_VIDEO_RECORDING,
  VIDEO_RECORDING_RESOURCE,
  RECIEVED_VIDEO_RECORDING_RESOURCE,
  QUERY_VIDEO_RECORDING,
  RECIEVED_QUERY_VIDEO_RECORDING,
  STOP_VIDEO_RECORDING,
  RECIEVED_STOP_VIDEO_RECORDING,
  UPDATELAYOUT_VIDEO_RECORDING,
  RECIEVED_UPDATELAYOUT_VIDEO_RECORDING,
  RESUME_DATA,
  RECIEVED_RESUME_DATA,
  SCHEDULE_CALL,
  RECIEVED_SCHEDULE_CALL,
  UPDATE_DURATION,
  ADD_NEW_PROJECT_TASK,
  NEW_PROJECT_TASK_RECIEVED,
  VIDEO_USERLIST,
  RECIEVED_VIDEO_USERLIST,
  REQUEST_GETUIDDETAILS,
  GETUIDDETAILS_RECEIVED,
  VIDEO_RECORDING_UPDATE,
  RECIEVED_VIDEO_RECORDING_UPDATE,
  RTC_TOKEN_BUILDER,
  RECIEVED_RTC_TOKEN_BUILDER,
  DELETE_TEAM_MEMBER,
  RTM_TRANSCRIPT_DATA,
  SAVE_RTM_TRANSCRIPT_DATA,
  STORE_RTM_TRANSCRIPT_LOGS,
  RTM_TRANSCRIPT_LOGS,
  GET_RTM_TRANSCRIPT_DATA,
  UPDATE_RTM_TRANSCRIPT_DATA,
  UPDATE_GUEST_DATA,
  STORE_RTM_TRANSCRIPT_MESSAGE_LOGS,
  RTM_TRANSCRIPT_MESSAGE_LOGS,
  UPDATE_SID_DATA,
  GET_PARTICIPANTS_LIST,
  RECIEVED_GET_PARTICIPANTS_LIST,
  SEND_PARTICIPANTS_LIST_ONEND,
  SEND_PARTICIPANTS_LIST_RECIEVED_ONEND,
  EDIT_SCREENER_ANSWER_RECEIVED
} from '../../constants/projects/ProjectConstants';

export const getPreProjectData = () => ({
  type: PRE_PROJECT_DATA
});

export const addProjectTask = payload => ({
  type: ADD_NEW_PROJECT_TASK,
  payload: payload
});

export const updateProjectTaskRecieved = payload => ({
  type: NEW_PROJECT_TASK_RECIEVED,
  payload: payload
});

export const preProjectDataRecieved = data => ({
  type: PRE_PROJECT_DATA_RECIEVED,
  payload: data
});

export const deleteProjectImageReceived = data => ({
  type: DELETE_PROJECT_IMAGE,
  payload: data
});

export const deleteProjectTaskReceived = data => ({
  type: DELETE_PROJECT_TASK,
  payload: data
});

export const updateProjectTask = data => ({
  type: EDIT_PROJECT_TASK,
  payload: data
});

export const createProjectLead = data => ({
  type: CREATE_PROJECT_LEAD,
  payload: data
});

export const createProjectResearcher = data => ({
  type: ADD_PROJECT_RESEARCHER,
  payload: data
});

export const createProjectObserver = data => ({
  type: ADD_PROJECT_OBSERVER,
  payload: data
});

export const getCriteriaAndBudget = data => ({
  type: GET_CRITERIA_AND_BUDGET,
  payload: data
});
export const addProjectResearcher = data => ({
  type: ADD_PROJECT_RESEARCHER,
  payload: data
});

export const CriteriaAndBudgetRecieved = data => ({
  type: RECIEVED_GET_CRITERIA_AND_BUDGET,
  payload: data
});
export const updateStage = data => ({
  type: UPDATE_STAGE,
  payload: data
});

export const getResolution = data => ({
  type: GET_RESOLUTION,
  payload: data
});

export const getResolutionRecieved = data => ({
  type: RECIEVED_GET_RESOLUTION,
  payload: data
});

export const getParticipant = data => ({
  type: GET_PARTICIPANTS_LIST,
  payload: data
});

export const getParticipantRecieved = data => (
{
  type: RECIEVED_GET_PARTICIPANTS_LIST,
  payload: data
});

export const SendParticpantsListOnEnd = data => ({
  type: SEND_PARTICIPANTS_LIST_ONEND,
  payload: data
});

export const SendParticpantsListReceivedOnEnd = data => ({
  type: SEND_PARTICIPANTS_LIST_RECIEVED_ONEND,
  payload: data
});

export const removeVideoCall = data => ({
  type: REMOVE_VIDEO_CALL,
  payload: data
});

export const removeVideoCallRecieved = data => ({
  type: RECIEVED_REMOVE_VIDEO_CALL,
  payload: data
});

export const GetEditSection = data => ({
  type: EDIT_SECTION,
  payload: data
});

export const editSectionRecieved = data => ({
  type: RECIEVED_EDIT_SECTION,
  payload: data
});

export const AddSection = data => ({
  type: ADD_SECTION,
  payload: data
});

export const AddSectionRecieved = data => ({
  type: RECIEVED_ADD_SECTION,
  payload: data
});

export const DragQuestion = data => ({
  type: DRAG_QUESTION,
  payload: data
});

export const DragQuestionRecieved = data => ({
  type: RECIEVED_DRAG_QUESTION,
  payload: data
});

export const CountNoParticipant = data => ({
  type: COUNT_NO_OF_PARTICIPANTS,
  payload: data
});

export const CountNoParticipantRecieved = data => ({
  type: RECIEVED_COUNT_NO_OF_PARTICIPANTS,
  payload: data
});

export const EditScreenerAnswerReceived = data => ({
  type: EDIT_SCREENER_ANSWER_RECEIVED,
  payload: data
});

export const VideoRecording = (data, token) => ({
  type: VIDEO_RECORDING,
  payload: data,
  token: token
});

export const VideoRecordingRecieved = data => ({
  type: RECIEVED_VIDEO_RECORDING,
  payload: data
});

export const VideoRecordingResource = (data, token) => ({
  type: VIDEO_RECORDING_RESOURCE,
  payload: data,
  token: token
});

export const VideoRecordingResourceRecieved = data => ({
  type: RECIEVED_VIDEO_RECORDING_RESOURCE,
  payload: data
});

export const QueryVideoRecording = (data, token) => ({
  type: QUERY_VIDEO_RECORDING,
  payload: data,
  token: token
});

export const QueryVideoRecordingRecieved = data => ({
  type: RECIEVED_QUERY_VIDEO_RECORDING,
  payload: data
});

export const UpdateLayoutVideoRecording = (data, token) => ({
  type: UPDATELAYOUT_VIDEO_RECORDING,
  payload: data,
  token: token
});

export const UpdateLayoutVideoRecordingRecieved = data => ({
  type: RECIEVED_UPDATELAYOUT_VIDEO_RECORDING,
  payload: data
});

export const StopVideoRecording = (data, token) => ({
  type: STOP_VIDEO_RECORDING,
  payload: data,
  token: token
});

export const StopVideoRecordingRecieved = data => ({
  type: RECIEVED_STOP_VIDEO_RECORDING,
  payload: data
});

export const Resume = data => ({
  type: RESUME_DATA,
  payload: data
});

export const ResumeRecieved = data => ({
  type: RECIEVED_RESUME_DATA,
  payload: data
});

export const Schedulecall = data => ({
  type: SCHEDULE_CALL,
  payload: data
});

export const SchedulecallRecieved = data => ({
  type: RECIEVED_SCHEDULE_CALL,
  payload: data
});

export const updateDuration = data => ({
  type: UPDATE_DURATION,
  payload: data
});

export const VideoRecordingUserList = (data, token) => ({
  type: VIDEO_USERLIST,
  payload: data,
  token: token
});

export const VideoRecordingUserListRecieved = data => ({
  type: RECIEVED_VIDEO_USERLIST,
  payload: data
});

export const getUIDDetails = data => ({
  type: REQUEST_GETUIDDETAILS,
  payload: data
});

export const getUIDDetailsReceived = data => ({
  type: GETUIDDETAILS_RECEIVED,
  payload: data
});

export const VideoRecordingUpdate = (data, token) => ({
  type: VIDEO_RECORDING_UPDATE,
  payload: data,
  token: token
});

export const VideoRecordingUpdateRecieved = data => ({
  type: RECIEVED_VIDEO_RECORDING_UPDATE,
  payload: data
});

export const RtcTokenBuilder = data => ({
  type: RTC_TOKEN_BUILDER,
  payload: data
});

export const RtcTokenBuilderRecieved = data => ({
  type: RECIEVED_RTC_TOKEN_BUILDER,
  payload: data
});

export const deleteTeamMemberReceived = data => ({
  type: DELETE_TEAM_MEMBER,
  payload: data
});

export const saveRtmTranscriptData = data => ({
  type: SAVE_RTM_TRANSCRIPT_DATA,
  payload: data
});

export const updateSid = (userId, videoid, sid) => {
  const data = { user_ID: userId, projectRespondentVideo_ID: videoid, sid: sid };
  return {
    type: UPDATE_SID_DATA,
    payload: data
  };
};

export const storeTranscriptLogs = data => ({
  type: STORE_RTM_TRANSCRIPT_LOGS,
  payload: data
});

export const RtmTranscriptLogsReceived = data => ({
  type: RTM_TRANSCRIPT_LOGS,
  payload: data
});

export const getRtmTranscriptData = data => ({
  type: GET_RTM_TRANSCRIPT_DATA,
  payload: data
});

export const updateRtmTranscriptData = data => ({
  type: UPDATE_RTM_TRANSCRIPT_DATA,
  payload: data
});

export const updateGuestData = data => ({
  type: UPDATE_GUEST_DATA,
  payload: data
});

export const RtmTranscriptDataReceived = data => ({
  type: RTM_TRANSCRIPT_DATA,
  payload: data
});

//Rutujas Code
export const storeTranscriptMessageLogs = data => ({
  type: STORE_RTM_TRANSCRIPT_MESSAGE_LOGS,
  payload: data
});

export const RtmTranscriptMessageLogsReceived = data => ({
  type: RTM_TRANSCRIPT_MESSAGE_LOGS,
  payload: data
});
